import React, { memo, useMemo, lazy, Suspense, useEffect } from 'react'
import { Loading } from 'components/shared'
import { useSelector } from 'react-redux'
import {
	LAYOUT_TYPE_CLASSIC,
	LAYOUT_TYPE_MODERN,
	LAYOUT_TYPE_SIMPLE,
	LAYOUT_TYPE_STACKED_SIDE,
	LAYOUT_TYPE_DECKED,
	LAYOUT_TYPE_BLANK
} from 'constants/theme.constant'
import useAuth from 'utils/hooks/useAuth'
import useDirection from 'utils/hooks/useDirection'
import useLocale from 'utils/hooks/useLocale'
import { useLocation, useNavigate, Navigate } from 'react-router-dom'
import {
	Breadcrumb,
	Button,
	Dialog,
	FSDrawer,
	Notification,
	Spinner,
	toast,
} from "components/ui";
import { protectedRoutes, publicRoutes } from 'configs/routes.config'


const layouts = {
	[LAYOUT_TYPE_CLASSIC]: lazy(() => import('./ClassicLayout')),
	[LAYOUT_TYPE_MODERN]: lazy(() => import('./ModernLayout')),
	[LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import('./StackedSideLayout')),
	[LAYOUT_TYPE_SIMPLE]: lazy(() => import('./SimpleLayout')),
	[LAYOUT_TYPE_DECKED]: lazy(() => import('./DeckedLayout')),
	[LAYOUT_TYPE_BLANK]: lazy(() => import('./BlankLayout')),
}

const Layout = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const layoutType = useSelector((state) => state.theme.layout.type)
	const { authenticated } = useAuth()
	useDirection()
	useLocale()

	const AppLayout = useMemo(() => {
		if (authenticated) {
			return layouts[layoutType]
		}
		return lazy(() => import('./AuthLayout'))
	}, [layoutType, authenticated])

	const openNotification = (type, message) => {
		toast.push(
			<Notification
				title={type.charAt(0).toUpperCase() + type.slice(1)}
				type={type}
			>
				{message}
			</Notification>
		);
	};

	let currentURL = location.pathname;
	let userData = useSelector((state) => state.auth.user);
	let loggedin_user_role = localStorage.getItem('loggedin_user_role');

	if (userData.menu_list != null && userData.menu_list.length > 0) {
		let routeAccessFlag = false;
		let routeData = protectedRoutes.filter((item) => item.path == currentURL);

		for (let item of userData.menu_list) {
			if (routeData.length > 0 && routeData[0].key != null && item.menu_key == routeData[0].key) {
				routeAccessFlag = true;
			}
		}

		if (!routeAccessFlag && currentURL != '/portal' && currentURL != '/sign-in' && currentURL != '/forgot-password' && loggedin_user_role != 'ADMN' && currentURL != '/') {
			openNotification("danger", "Permission denied!");
			return <Navigate to="/portal" />
		}
	} else {
		if (currentURL != '/portal' && currentURL != '/sign-in' && currentURL != '/forgot-password' && loggedin_user_role != 'ADMN' && currentURL != '/') {
			openNotification("danger", "Permission denied!");
			return <Navigate to="/portal" />
		}
	}

	return (
		<Suspense
			fallback={
				<div className="flex flex-auto flex-col h-[100vh]">
					<Loading loading={true} />
				</div>
			}
		>
			<AppLayout />
		</Suspense>
	)
}

export default memo(Layout)