import React, { Suspense } from "react";
import { Button, Dialog, Spinner, Table } from "components/ui";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

const { Tr, Th, Td, THead, TBody } = Table;

const PDFExport = ({
  columns = [],
  pdfData = [],
  isOpen,
  onClose,
  pdfName = "report",
}) => {
  const generatePDF = () => {
    const doc = new jsPDF();
    autoTable(doc, { html: "#pdf-table" });
    doc.save(`${pdfName}.pdf`);
  };

  return (
    <Dialog
      isOpen={isOpen}
      width="100%"
      onClose={onClose}
      onRequestClose={onClose}
    >
      <h5 className="mb-4">Export As PDF</h5>

      <div className="flex flex-col justify-between">
        <Suspense fallback={<Spinner />}>
          <Table id="pdf-table">
            <THead>
              <Tr>
                {columns.map((column, i) => (
                  <Th className="border border-indigo-500" key={i}>
                    {column.Header}
                  </Th>
                ))}
              </Tr>
            </THead>
            <TBody>
              {pdfData.map((row, i) => (
                <Tr
                  className={`${i % 2 === 0 ? "bg-gray-50" : "bg-gray-100"}`}
                  key={i}
                >
                  {Object.keys(row).map((cell, i) => (
                    <Td className="border border-gray-400" key={i}>
                      {row[cell]}
                    </Td>
                  ))}
                </Tr>
              ))}
            </TBody>
          </Table>
        </Suspense>
        <div className="text-right mt-6">
          <Button className="mr-2" variant="solid" onClick={generatePDF}>
            Print
          </Button>
          <Button className="mr-2" variant="plain" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
export default PDFExport;
