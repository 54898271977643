const appConfig = {
  // apiPrefix: "http://192.168.5.217/bwu-buis-api",
  apiPrefix: "https://brainware-india.net/bwu-buis-api",
  // apiPrefix: "https://buisapi.brainware-india.net",
  authenticatedEntryPath: "/portal",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/",
  enableMock: false,
  currentDate: new Date().toISOString().split('T')[0],
  siteAddress: "398, Ramkrishnapur Road, Barasat, North 24 Parganas, Kolkata - 700 125"
};

export default appConfig;
