import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = ({
  breadcrumbs = [
    {
      label: "Home",
      link: "/",
    },
  ],
}) => {
  return (
    <nav
      className="top-14 border p-1 md:p-3 border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-800 dark:border-gray-700 breadcrumbarea"
      aria-label="Breadcrumb"
      style={{ zIndex: 15, position: "sticky" }}
    >
      <ol className="inline-flex items-center space-x-1 md:space-x-1 ">
        {breadcrumbs.map((breadcrumb, i) => (
          <li className="inline-flex items-center" key={i}>
            <Link
              to={breadcrumb.link}
              className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
            >
              {i > 0 ? (
                <svg
                  aria-hidden="true"
                  className="w-3 h-3 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              ) : (
                <svg
                  aria-hidden="true"
                  className="w-4 h-4 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                </svg>
              )}
              {breadcrumb.label}
            </Link>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
