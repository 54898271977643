import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

const Th = React.forwardRef((props, ref) => {
  const { children, className, asElement: Component, ...rest } = props;

  const thClass = classNames(
    Component !== "th" && "th",
    className,
    "text-white bg-indigo-600"
    // "text-white sticky top-0 z-20"
  );

  return (
    <Component
      className={thClass}
      {...rest}
      ref={ref}
      style={{ color: "#fff" }}
    >
      {children}
    </Component>
  );
});

Th.propTypes = {
  asElement: PropTypes.string,
};

Th.defaultProps = {
  asElement: "th",
};

export default Th;
