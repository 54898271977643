import React from "react";
import useResponsive from "utils/hooks/useResponsive";
import Drawer from "./Drawer";

const FSDrawer = ({
  children,
  title,
  footerComponent,
  drawerOpen,
  onClose,
  className
}) => {
  const { windowWidth } = useResponsive();
  return (
    <Drawer
      title={title}
      isOpen={drawerOpen}
      onClose={onClose}
      onRequestClose={onClose}
      placement="top"
      height={windowWidth < 1400 ? 600 : 850}
      footer={footerComponent}
      className={className}
    >
      {children}
    </Drawer>
  );
};

export default FSDrawer;
