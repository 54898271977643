import { React, useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store'
import Theme from 'components/template/Theme'
import Layout from 'components/layout'
import history from './history'
import mockServer from './mock'
import appConfig from 'configs/app.config'
import packageJson from "../package.json";
import {
	Breadcrumb,
	Button,
	Dialog,
	FSDrawer,
	Notification,
	Spinner,
	toast,
} from "components/ui";
import './locales'
import './App.css';
import './gradient.css';

const environment = process.env.NODE_ENV;

/**
 * Set enableMock(Default false) to true at configs/app.config.js 
 * If you wish to enable mock api
 */

if (environment !== 'production' && appConfig.enableMock) {
	mockServer({ environment })
}

//disable right click
// document.addEventListener('contextmenu', event => event.preventDefault());

// the app  functional component 
function App() {
	const [open, setOpen] = useState(false);

	let getBrowserCache = () => {
		let version = localStorage.getItem('version');

		if (version != packageJson.version) {
			setOpen(true);
		}
	};

	let clearBrowserCache = () => {
		if ('caches' in window) {
			caches.keys().then((names) => {
				names.forEach(name => {
					caches.delete(name);
				})
			});
			localStorage.removeItem("session_expiry_time");
			localStorage.setItem('version', packageJson.version);
			window.location.reload(true);
		}
		localStorage.setItem('version', packageJson.version);
	}

	useEffect(() => {
		getBrowserCache();
	}, []);

	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<BrowserRouter history={history}>
					<Theme>
						<Layout />
					</Theme>
				</BrowserRouter >
			</PersistGate>
			<Dialog
				className='versionchangedialog'
				isOpen={open}
				onClose={() => setOpen(true)}
				onRequestClose={() => setOpen(true)}
			>
				<h5 className="mb-2">Update Available</h5>
				<p>To get the latest website updates, Click <b>"Ok"</b></p>
				<p>Latest Version: {packageJson.version}</p>
				<div className="text-right mt-6">
					<Button
						className="w-20"
						variant="solid"
						onClick={() => {
							setOpen(false);
							clearBrowserCache();
						}}
					>
						Ok
					</Button>
				</div>
			</Dialog>
		</Provider>
	)
}

export default App
