import { React } from "react";
import PropTypes from "prop-types";
// import useAuthority from "utils/hooks/useAuthority";
import { useSelector } from "react-redux";
// import useApiData from "utils/hooks/useApiData";

const AuthorityCheck = (props) => {
  // const { getList, postData, postResponse } = useApiData();

  // const role = useSelector((state) => state.auth.user.role_name);
  const roleId = useSelector((state) => state.auth.user.role_id);
  const menuList = useSelector((state) => state.auth.user.menu_list);

  const { children } = props;
  // const userAuthority = [role];
  // const roleMatched = useAuthority(userAuthority, authority);

  let showMenu = false;
  // let keyName = props?.key ? children?.props?.children?.key : children?.props?.nav?.key;
  let keyName = children?.props?.children?.key ? children?.props?.children?.key : children?.props?.nav?.key;

  if (menuList.length > 0) {
    for (let i in menuList) {
      if (menuList[i].menu_key == keyName && roleId != 1) {
        showMenu = true;
      }
    }
  }

  if (roleId == 1) {
    showMenu = true;
  }

  if (showMenu) {
    return children;
  } else {
    return <></>;
  }
  // return roleMatched ? children : <></>;
};

AuthorityCheck.propTypes = {
  userAuthority: PropTypes.array,
  authority: PropTypes.array,
};

export default AuthorityCheck;
